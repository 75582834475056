<template>
  <div>
    <a-modal title="周期活动预览" :visible="visible" width="1200px" :destroyOnClose="true" :footer="null"
      @cancel="handleCancel" :maskClosable="false">
      <a-table :rowKey="(record, index) => index" :columns="table.columns" :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false" bordered @change="pageChange">
        <span slot="startTime" slot-scope="text, record">
          {{ record.startTime }} 至 {{ record.endTime }}
        </span>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { table } from './help';
import _ from 'lodash';
import { buildPeriodicActivityPreview } from '../../../api/enterpriseServiceActivitiesManagement';
export default {
  props: {
    // 请求参数
    params: {
      type: Object,
      default: () => { },
    },
    // 是否弹框
    visible: {
      type: Boolean,
      required: true,
      default: () => false
    },
  },
  data() {
    return {
      table: [],
    };
  },
  created() {
    this.table = _.cloneDeep(table);
    this.getPreviewList();
  },

  methods: {
    handleCancel() {
      this.$emit('close');
    },
    // 切换分页/数量
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getPreviewList();
    },
    /**网络请求 */
    // 获取预览列表
    async getPreviewList() {
      try {
        const res = await buildPeriodicActivityPreview({
          ...this.params,
          page: this.table.pagination.current - 1,
          size: this.table.pagination.pageSize,
        })
        if (res.code === this.$SUCCESS_CODE) {
          console.log(res.data);
          this.table.dataInfo = res.data.data;
          this.table.pagination.total = res.data.total;
        }
      } catch (error) {

      }
    }
  },
};
</script>

<style lang="scss" scoped></style>