export const LineStatusEnum = {
  NORMAL: 0, // 正常,
  NOT_SESSION: 1, // 无场次
  SESSION_BE_OCCUPIED: 2, // 被占用
};

export const LineStatusTextEnum = {
  0: '正常',
  1: '当天无场次',
  2: '当前场次被占用',
};

export const table = {
  //数据
  dataInfo: [],
  //title数据
  columns: [
    {
      title: '序号',
      key: 'index',
      dataIndex: 'index',
      customRender: (text, row, index) => {
        let tempIndex = index + 1;
        return tempIndex > 9 ? tempIndex : `0${tempIndex}`;
      },
    },
    { title: '活动时间', key: 'startTime', dataIndex: 'startTime', scopedSlots: { customRender: 'startTime' } },
    { title: '星期', key: 'weekStr', dataIndex: 'weekStr' },
    { title: '活动场地', key: 'placeName', dataIndex: 'placeName' },
    { title: '维护场次', key: 'maintainTime', dataIndex: 'maintainTime' },
    {
      title: '关联状态',
      key: 'status',
      dataIndex: 'status',
      customRender: (text, row, index) => {
        return LineStatusTextEnum[text] || '';
      },
    },
  ],
  //分页数据
  pagination: {
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  },
};
